import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import MotorsTable from '../components/motorstable';
import Markdown from "react-markdown";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Motors = () => {
    const data = useStaticQuery(query);
    const [show, setShow] = useState(true);

    return (
        <Layout pageInfo={{ pageName: "index" }}>
            <SEO title="Motors" keywords={[`gatsby`, `react`, `bootstrap`]} />
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-12 gPadding">
                        <div className="display-3">{data.strapiMotors.title}</div>
                        <hr></hr>
                        <Markdown source={data.strapiMotors.description} className="py-2"></Markdown>
                        {/* <p>{data.strapiMotors.subtitle}</p> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 gPadding">
                        <button className={`aButton motors mr-3 ${show ? "active" : ""}`} onClick={() => { setShow(true) }}>Brushless</button>
                        <button className={`aButton motors ${show ? "" : "active"}`} onClick={() => { setShow(false) }}>Brushed</button>
                    </div>
                </div>

                <div className="row py-5">
                    <div className="col-md-12">
                        {show ?
                            <MotorsTable data={data.strapiMotors.Motor_brushless}></MotorsTable>
                            :
                            <MotorsTable data={data.strapiMotors.Motor_brushed} num={1}></MotorsTable>
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const query = graphql`
  query {
    strapiMotors {
        title
        subtitle
        description
        Motor_brushless {
          model
          voltage_operating_range
          nominal
          no_load_speed
          current
          at_maximum_speed
          efficiency_current
          torque
          noname
          output
          eff
          stall_current
          torque1
          noname2
        }
        Motor_brushed {
            model
            voltage_operating_range
            nominal
            no_load_speed
            current
            at_maximum_speed
            efficiency_current
            torque
            noname
            output
            eff
            stall_current
            torque1
            noname2
          }
      }
  }
`;

export default Motors;
