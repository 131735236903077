import React from 'react';
import Table from 'react-bootstrap/Table';

const MotorsTable = ({ data, num }) => {
    console.log(num);
    return (
        <>
            <Table responsive bordered>
                <thead style={{ backgroundColor: "darkGrey", color: "white" }}>
                    <tr className="text-center">
                        <th width="40%" rowspan="3">MODEL</th>
                        <th colspan="2">VOLTAGE</th>
                        <th colspan="2">NO LOAD</th>
                        <th colspan="6">AT MAXIMUM EFFICIENCY</th>
                        {num == 1 ?
                            <th colspan="3">STALL</th>
                            :
                            <th colspan="3">MAX</th>
                        }
                    </tr>
                    <tr className="text-center">
                        <th rowspan="2">OPERATING<br />RANGE</th>
                        <th width="35%" rowspan="2">NOMINAL</th>
                        <th>SPEED</th>
                        <th>CURRENT</th>
                        <th>SPEED</th>
                        <th>CURRENT</th>
                        <th colspan="2">TORQUE</th>
                        <th>OUTPUT</th>
                        <th>EFF</th>
                        <th>CURRENT</th>
                        <th colspan="2">TORQUE</th>
                    </tr>
                    <tr className="text-center">
                        <th>rpm</th>
                        <th>A</th>
                        <th>rpm</th>
                        <th>A</th>
                        <th>g-cm</th>
                        <th>mN-m</th>
                        <th>W</th>
                        <th>%</th>
                        <th>A</th>
                        <th>g-cm</th>
                        <th>mN-m</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, key) => (
                        <tr key={key} className="text-center">
                            <td>{item.model}</td>
                            <td>{item.voltage_operating_range}</td>
                            <td>{item.nominal}</td>
                            <td>{item.no_load_speed}</td>
                            <td>{item.current}</td>
                            <td>{item.at_maximum_speed}</td>
                            <td>{item.efficiency_current}</td>
                            <td>{item.torque}</td>
                            <td>{item.noname}</td>
                            <td>{item.output}</td>
                            <td>{item.eff}</td>
                            <td>{item.stall_current}</td>
                            <td>{item.torque1}</td>
                            <td>{item.noname2}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

export default MotorsTable;